import React from 'react'

// import Header from './Home/Header.js'
// import Offer from './Home/Offer.js'


// import Features from './Home/Features.js'
// import Stats from './Home/Stats.js'
// import Content from './Home/Content.js'
// import CTA from './Home/CTA.js'
// import Team from './Home/Team.js'
// import Contact from './Home/Contact.js'
// import Footer from './Home/Footer.js'

function Home() {
  return (
    <React.Fragment>
      {/* <Header /> */}
      {/* <Offer /> */}
      {/* <Features /> */}
      {/* <Stats /> */}
      {/* <Content /> */}
      {/* <CTA /> */}
      {/* <Contact /> */}
      {/* <Team /> */}
      {/* <Footer /> */}
      <div className='h-screen w-full flex items-center text-center text-gray-700'>
        <div className='w-full'>
          Have something to say?
          <br />
          Drop a line to <a href='mailto:hello@codematters.llc' className='underline hover:no-underline text-indigo-500 hover:text-indigo-600'>hello@codematters.llc</a></div>
      </div>
    </React.Fragment>
  );
}


export default Home;
